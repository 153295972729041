import { Outlet, Link } from "react-router-dom";
import LogoSvg from '../../assets/logo.svg?react'

import styles from "./Layout.module.css";
import {setLocalAccessToken} from "../../helper/localStoradge";
import {useAuth} from "../../Auth/AuthContect";
import {UserAppResponse} from "../../api";
import LogOutComponent from "./LogOutComponent";

export type TProps = {
    user?: UserAppResponse;
    isLoading?: boolean;
}
const Layout = ({user, isLoading}: TProps) => {
    const { setAuthToken } = useAuth();
    const logOut=() => {
        setLocalAccessToken('')
        setAuthToken('')
    }

    return (
        <div className={styles.layout}>
            {!isLoading ? <header className={styles.header} role={"banner"}
                     style={{
                         backgroundColor: !!user?.client?.background_color ? user?.client?.background_color : '#225FBF',
                         color: !!user?.client?.button_color ? user?.client?.button_color : '#FFF'}}>
                <div className={styles.headerContainer}>
                    <a href="/" className={styles.headerTitleContainer}>
                        {(!!user?.client?.logo
                            ? <img src={user?.client?.logo} alt={"client-logo"}
                                   className={styles.headerTitleContainerImg}/>
                            : <LogoSvg/>)}
                    </a>

                    <div className={styles.headerRightText} onClick={logOut}>
                        <LogOutComponent color={user?.client?.button_color} />
                        Log out</div>
                </div>
            </header> : <></>}

            <Outlet />
        </div>
    );
};

export default Layout;
