import { ChatAppResponseOrError, ChatAppRequest, signInRequest} from "./models";

const BACKEND_URI = document.location.origin
function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    return headers;
}

export async function chatApi(request: any, token: string): Promise<Response> {
    return await fetch(`/api/v1/clients/current/chat/`, {
        method: "POST",
        headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`},
        body: JSON.stringify(request)
    });
}
export async function signInApi(request: signInRequest): Promise<Response> {
    return await fetch(`/api/v1/auth/token/`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(request)
    });
}
export async function getSampleQuestions(token: string): Promise<Response> {
    return await fetch(`/api/v1/users/current/`, {
        method: "GET",
        headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`},
    });
}
export async function UploadDocumentsApi(request: any): Promise<Response> {
    let formObjectInside = Object.fromEntries(request.entries());
    return await fetch(`${BACKEND_URI}/upload_documents`, {
        method: "POST",
        headers: {"Content-Type": "multipart/form-data"},
        body: request
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
};
