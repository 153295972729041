import { createContext, useContext } from "react";
import {UserAppResponse} from "../api";
export const initialStateFroUser = {
    first_name: '',
    last_name: '',
    username: '',
    client:  {
        name: '',
        questions:[{text: ''}],
        logo: ''
    }
}
export const AuthContext = createContext({
    authToken:'',
    setAuthToken: (data: string) => {},
    user: initialStateFroUser,
    setUser: (data: UserAppResponse) => {}
});

export function useAuth() {
    return useContext(AuthContext);
}
