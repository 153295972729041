import ClearIcon from '../../assets/clear.svg?react';
import styles from "./ClearChatButton.module.css";
import "./ClearChatButton.module.css";
import Modal from "react-modal";
import {useState} from "react";
import { Button } from 'antd';
const customStyles = {
    content: {
        margin: '30px auto',
        height: 205,
        width: 500
    },
};
interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}



export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const onOpen = () => setIsOpen(true)
    const onClose = () => setIsOpen(false)
    return (
        <>
                <div className={`${styles.container} ${className ?? ""}`}>
                    <Button type="text" icon={<ClearIcon />} disabled={disabled} onClick={onOpen} className={styles.clearBtnText}>
                        Clear chat
                    </Button>
                </div>
                <Modal
                    isOpen={isOpen}
                    className={styles.dialogClass}
                    style={customStyles}
                    onRequestClose={onClose}
                    overlayClassName={styles.dialogOverlayClass}
                >
                    <div className={styles.dialogTitle}>
                        Clear chat
                    </div>
                    <div className={styles.dialogBody}>
                        Do you really want to clear the chat history?
                        You will permanently lose all the messages from the current session.
                    </div>
                    <div className={styles.dialogFooter}>
                            <div onClick={onClose} className={styles.defaultBtn}>Cancel</div>
                            <div onClick={onClick} className={styles.primaryBtn}>Clear chat</div>
                    </div>
                </Modal>
            </>
    );
};
