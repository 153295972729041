import styles from "../AnalysisPanel/AnalysisPanel.module.css";
import {keyForMessage, ResponseChoice} from "../../api";

type TProps =  {
    answer: ResponseChoice
}
export const ThoughtProcess = ({answer}: TProps) => {
    return (
        <div className={styles.thoughtProcess}>
            <div className={styles.thoughtProcessItem}>
            <div>
                <div className={styles.thoughtProcessTitle}>
                    Search for:
                </div>
                <div className={styles.thoughtProcessText}>
                    {answer.question}
                </div>
            </div>
            <div>
                <div className={styles.thoughtProcessTitle}>
                    Prompt:
                </div>
                <div className={styles.thoughtProcessText}>
                    {answer.generated_question}
                </div>
            </div>
            <div>
                <div className={styles.thoughtProcessTitle}>
                    Conversation:
                </div>
                {!!answer?.message && <div className={styles.thoughtProcessText}>
                    &#123;
                    {Object.keys(answer?.message)?.reverse()?.map((key) => !!key && `'${key}': "${answer?.message[key as keyForMessage]}"`).join(', ')}
                    &#125;
                </div>}
            </div>
            </div>
        </div>
    )
}