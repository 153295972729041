import React, {useState, useEffect, useCallback} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Login from "./pages/login/Login";
import Chat from "./pages/chat/Chat";
import {getLocalAccessToken, WEB_STORAGE} from "./helper/localStoradge";
import {AuthContext, initialStateFroUser} from "./Auth/AuthContect";
import {getSampleQuestions, UserAppResponse} from "./api";

var layout;
layout = <Layout />;

const AppRoutes = () => {
        const [authToken, setAuthTokens] = useState<string>('');
        const [user, setUser] = useState<UserAppResponse>(initialStateFroUser);
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
        const setTokens = (data: string) => {
            localStorage.setItem(WEB_STORAGE.ACCESS_TOKEN, data);
            setAuthTokens(data);
            setIsLoading(false)
        };

        useEffect(() => {
            const token =  getLocalAccessToken()
            setTokens(token || '')
        }, [])

    const getUserData = useCallback(async() => {
        if(!authToken) return
        setIsLoadingUser(true)
        const userResponse = await getSampleQuestions(authToken)
        const user: UserAppResponse = await userResponse.json();
        setIsLoadingUser(false)
        if(userResponse.status === 401) {
            setAuthTokens('')
            return;
        }
        setUser(user)
    }, [authToken])

        useEffect(() => {
            getUserData()
        }, [authToken])

        return (
            <AuthContext.Provider value={{ authToken, setAuthToken: setTokens, user, setUser }}>
                {!!authToken?.length && <Layout user={user} isLoading={isLoadingUser}/>}
                {!isLoading && <Router>
                    {authToken ?
                        <Routes>
                            <Route path="/" element={<Chat/>}/>
                        </Routes> :
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                        </Routes>
                    }
                </Router>}
            </AuthContext.Provider>
        );
}



ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <AppRoutes />
    </React.StrictMode>
);
