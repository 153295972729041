import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import cn from "classnames";

export const AnswerLoading = () => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <div className={cn(styles.answerContainer, styles.answerLoadingContainer)} >
                <div>
                    <p className={cn(styles.answerText, styles.answerLoadingText)}>
                        Preparing the answer for you...
                    </p>
                    <div className={styles.loadingBar}/>
                </div>
            </div>
        </animated.div>
    );
};
