import CloseIcon from '../../assets/close.svg?react'
import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { ResponseChoice} from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import {ThoughtProcess} from "../ThoughtProcess/ThoughtProcess";
import {ConfigProvider, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';


interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    citationHeight: string;
    answer: ResponseChoice;
    closePanel: () => void;
}
const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };
export const AnalysisPanel = ({ answer, activeTab, className, onActiveTabChanged, closePanel }: Props) => {

    return (
        <div className={styles.chatAnalysisPanelWrapper}>
            <div className={styles.chatAnalysisPanelClose} onClick={closePanel}>
                <CloseIcon/>
            </div>
            <ConfigProvider
                theme={{
                    components: {
                            Tabs: {
                                inkBarColor:"#5495FF",
                                itemSelectedColor:"#333D47",
                                itemHoverColor:"#333D47",
                                itemColor:"#818D99"
                            }}
                }}
            >
            <Tabs
                className={styles.chatAnalysisPanel}
                activeKey={activeTab}
                onChange={key => onActiveTabChanged(key as AnalysisPanelTabs)}
                tabBarStyle={{
                    borderBottom: '1px solid #D1D7E5',
                    padding: '25px 24px 0',
                }}
                tabBarExtraContent={null}
            >
                <TabPane
                    tab="THOUGHT PROCESS"
                    key={AnalysisPanelTabs.ThoughtProcessTab}
                    className={styles.chatAnalysisPanelTab}
                >
                    {!!Object.keys(answer.message)?.length && <ThoughtProcess answer={answer}/>}
                </TabPane>
                <TabPane
                    tab="SUPPORTING CONTENT"
                    key={AnalysisPanelTabs.SupportingContentTab}
                    disabled={!answer.source_documents?.length}
                >
                    {answer.source_documents?.length &&
                        <SupportingContent supportingContent={answer.source_documents}/>}
                </TabPane>


            </Tabs>
            </ConfigProvider>

        </div>
    );
};


