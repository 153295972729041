import { useMemo } from "react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import {ResponseChoice, ResponseSourceDocuments} from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import SourceIcon from "../../assets/source.svg?react"
import { Space } from "antd";

interface Props {
    answer: ResponseChoice;
    isSelected?: boolean;
    isStreaming: boolean;
}

export const Answer = ({
    answer,
    isStreaming,
}: Props) => {
    const messageContent = answer.message?.content || '';
    const sourceDocuments: ResponseSourceDocuments[] =  Array.from(new Set(answer?.source_documents?.map(obj => obj?.doc_id)))?.map(value => answer?.source_documents?.find(obj => obj?.doc_id === value)).filter(Boolean)  as ResponseSourceDocuments[]
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    return (
        <Space className={`${styles.answerContainer}`} direction={"vertical"}>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>

            {!!sourceDocuments?.length && (
                <div>
                    <Space direction={"vertical"}>
                        <div className={styles.citationLearnMore}>Citations:</div>
                        <Space direction="horizontal" className={styles.sourceDocumentsWrapper}>
                            {!!sourceDocuments?.length && sourceDocuments?.map((x: ResponseSourceDocuments, i) => {
                                return (
                                    <div className={styles.sourceDocumentsItem}>
                                        <SourceIcon/>
                                        <span key={i} className={styles.citation}>
                                    {x.doc_id}
                                </span>
                                    </div>

                                );
                            })}
                        </Space>
                    </Space>
                </div>
            )}
        </Space>
    );
};
