import styles from './Login.module.css'
import LoginIcon from '../../assets/login.svg?react'
import LogoIcon from '../../assets/logo.svg?react'
import {useCallback, useState} from "react";
import { LoginAppResponseOrError, signInApi} from "../../api";
import {setLocalAccessToken} from "../../helper/localStoradge";
import {useAuth} from "../../Auth/AuthContect";
import cn from 'classnames'
import Lottie from 'react-lottie-player'
import lottieJson from '../../assets/data.json'
const Login = () => {

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { setAuthToken } = useAuth();
    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            const response = await signInApi({username, password})
            const parsedResponse: LoginAppResponseOrError = await response.json();
            setIsLoading(false)
            if(parsedResponse?.access) {
                setLocalAccessToken(parsedResponse.access)
                setAuthToken(parsedResponse.access)
            }
            if(parsedResponse?.detail){
                setError(parsedResponse.detail)
            }
        }
        catch(e){
        }

    }, [username, password])

    return(
        <div className={styles.loginContainer}>
        <div className={styles.loginScreen}>
            <div className={styles.loginHeader}>
                {/*<div className={styles.imageWrapper}>*/}
                {/*    <LoginIcon />*/}
                {/*</div>*/}
                {/*<div className={styles.logoWrapper}>*/}
                {/*    <LogoIcon />*/}
                {/*</div>*/}
                <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 360, height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8, overflow: "hidden" }}
                />
            </div>
            <div className={styles.loginContent}>
            <div className={styles.loginContentTitle}>
                {!!error?.length ? 'Something went wrong...' : 'Welcome!'}
            </div>
            <div className={styles.loginContentSubtitle}>
                {!!error?.length ? error : 'Please sign in with your credentials'}
            </div>

                <form className={styles.formWrapper} id="sign-in-form">
                    <div className={styles.inputWrapper}>
                        <div className={styles.textInputLabel}>Login</div>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className={cn(styles.textInput,!!error?.length && styles.errorInput)}

                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <div className={styles.textInputLabel}>Password</div>
                        <input
                            type="password"
                            className={cn(styles.textInput, !!error?.length && styles.errorInput)}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}

                        />
                    </div>
                    <button type="button" onClick={onSubmit} disabled={!username || !password || isLoading} className={styles.btnSignIn}>
                        Sign in
                    </button>
                </form>

            </div>
        </div>
            <div className={styles.footerText}>
                {new Date().getFullYear()} © LeverX International Company LLC.
                <br />
                All rights reserved.
            </div>
        </div>
    )
}

export default Login