import styles from "./Example.module.css";
import cn from 'classnames'
import VectorSvg from '../../assets/vector.svg?react'
interface Props {
    text: string;
    value: string;
    className?: string;
    onClick: (value: string) => void;
}

export const Example = ({ text, value, onClick, className }: Props) => {
    return (
        <div className={cn(styles.example,className)} onClick={() => onClick(value)}>
            <div className={styles.vectorWrapper}>
                <VectorSvg />
            </div>

            <p className={styles.exampleText}>{text}</p>
        </div>
    );
};
