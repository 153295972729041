import { parseSupportingContentItem } from "./SupportingContentParser";

import styles from "./SupportingContent.module.css";
import {ResponseSourceDocuments} from "../../api";

interface Props {
    supportingContent: ResponseSourceDocuments[];
}

export const SupportingContent = ({ supportingContent }: Props) => {
    return (
        <div className={styles.supportingContentNavList}>
            {supportingContent.map((doc, i) => {
                return (
                    <div className={styles.supportingContentItem}>
                        <h4 className={styles.supportingContentItemHeader}>{doc.doc_id}</h4>
                        <h4 className={styles.supportingContentItemScore}>Similarity score: {doc.score}</h4>
                        <p className={styles.supportingContentItemText}>{doc.page_content}</p>
                    </div>
                );
            })}
        </div>
    );
};
