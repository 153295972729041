import { useRef, useState, useEffect } from "react";

import styles from "./Chat.module.css";
import {chatApi, ChatAppResponseOrError, ChatAppRequest, ResponseChoice} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import Preview from "./Preview";
import {ClearChatButton} from "../../components/ClearChatButton";
import JournalIcon from '../../assets/journal.svg?react';

import {useAuth} from "../../Auth/AuthContect";
import { Button } from "antd";

const Chat = () => {
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const {authToken, setAuthToken} = useAuth()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(AnalysisPanelTabs.ThoughtProcessTab);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState< ResponseChoice[]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<ResponseChoice[]>([]);
    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;
        error && setError(undefined);
        setIsLoading(true);
        setActiveAnalysisPanelTab(undefined);

        try {
            const request: ChatAppRequest = {
                is_new: !answers.length,
                question: question,
            };

            const response = await chatApi(request, authToken);
            if (!response.body) {
                throw Error("No response body");
            }
            if(response.status === 401) {
                setAuthToken('')
                return;
            }
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, parsedResponse as ResponseChoice]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers]);


    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };


    return (
        <div className={styles.container}>

            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <Preview
                            onExampleClicked={onExampleClicked}
                            makeApiRequest={makeApiRequest}
                            isLoading={isLoading}
                        />
                    ) : (
                        <div className={styles.chatMessageStream}>
                            <div className={styles.chatMessageStreamWrapper}>
                            {isStreaming &&
                                streamedAnswers.map((streamedAnswer, index) => (
                                    <div key={index} className={styles.chatMessageItemWrapper}>
                                        <UserChatMessage message={streamedAnswer?.question} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={true}
                                                key={index}
                                                answer={streamedAnswer}
                                                isSelected={false}
                                            />
                                        </div>
                                    </div>
                                ))}

                            {!isStreaming &&
                                answers.map((answer, index) => (
                                    <div key={index} className={styles.chatMessageItemWrapper}>
                                        <UserChatMessage message={answer?.question} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={false}
                                                key={index}
                                                answer={answer}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {isLoading && (
                                <div className={styles.chatMessageItemWrapper}>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </div>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError
                                            error={'Sorry, the system is currently not responding. Please try again later.'}
                                            onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                            </div>
                            <div className={styles.chatInput}>
                                <QuestionInput
                                    clearOnSend
                                    placeholder="Type a new question here..."
                                    disabled={isLoading}
                                    onSend={question => makeApiRequest(question)}
                                    inside
                                />
                            </div>
                            <div className={styles.bottomButtons}>
                                <div className={`${styles.containerBtnInspect}`}>
                                    <Button icon={<JournalIcon />} onClick={() => {
                                        if(activeAnalysisPanelTab) setActiveAnalysisPanelTab(undefined)
                                        else setActiveAnalysisPanelTab(AnalysisPanelTabs.ThoughtProcessTab)
                                    }} disabled={!answers.length}  type="text" className={styles.inspectButton}>
                                      {activeAnalysisPanelTab ? 'Hide Inspect Panel' : "Show Inspect Panel"}
                                    </Button>
                                </div>
                            <ClearChatButton className={styles.commandButton}
                                             onClick={clearChat}
                                             disabled={!lastQuestionRef?.current || isLoading} />
                            </div>
                        </div>
                    )}
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <>
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[answers.length - 1]}
                        activeTab={activeAnalysisPanelTab}
                        closePanel={() => setActiveAnalysisPanelTab(undefined)}
                    />
                    </>
                )}
            </div>
        </div>
    );
};

export default Chat;
