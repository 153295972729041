import { Example } from "./Example";

import styles from "./Example.module.css";
import {useAuth} from "../../Auth/AuthContect";
import {useRef, useEffect, useState} from "react"
import {getCalculatedHeight} from "./helper";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "What is AFC 1015 BF?",
        value: "What is AFC 1015 BF?"
    },
    { text: "How to create an emulsion with ECOCOOL fluid?",
      value: "How to create an emulsion with ECOCOOL fluid?" },
    { text: "What can AFC 1015 BF be used for?",
      value: "What can AFC 1015 BF be used for?" }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    const {user} = useAuth()
    const [calculatedHeight2, setCalculatedHeight2] = useState<number | null>(null);
    const [calculatedHeight1, setCalculatedHeight1] = useState<number | null>(null);
    const [calculatedHeight3, setCalculatedHeight3] = useState<number | null>(null);

    const examplesNavListRightRef = useRef<HTMLDivElement>(null);
    const examplesNavListRight1Ref = useRef<HTMLDivElement>(null);
    const examplesNavListRight2Ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const height2 = getCalculatedHeight(examplesNavListRight1Ref);
        if (height2 !== null && height2) {
            setCalculatedHeight2(height2);
        }
        const height3 = getCalculatedHeight(examplesNavListRight2Ref);
        if (height3 !== null && height3) {
            setCalculatedHeight3(height3);
        }
        const height = getCalculatedHeight(examplesNavListRightRef);
        if (height3 !== null && height) {
            if(height2 && height3){
            const leftHeight = height2 + height3 + 47
                if(height > leftHeight){
                    setCalculatedHeight1(height)
                }  else {
                    setCalculatedHeight1(leftHeight)
                }
            }
        }
    }, [user?.client?.questions]);

    return (
        <div className={styles.examplesNavList}>
            <div className={styles.examplesNavListLeft}
                 ref={examplesNavListRightRef}
                 style={{ height:`calc(${calculatedHeight1}px)` }}
            >
                <Example text={user?.client?.questions[0]?.text || ''} value={user?.client?.questions[0]?.text} className={styles.examplesNavListLeftItem} onClick={onExampleClicked} />
            </div>
            <div className={styles.separatorVertical}/>
            <div className={styles.examplesNavListRight}>
                <div className={styles.examplesNavListRightWrapper}
                     ref={examplesNavListRight1Ref}
                     style={{ height:`calc(${calculatedHeight2}px)` }}
                >
                <Example text={user?.client?.questions[1]?.text || ''} value={user?.client?.questions[1]?.text || ''} className={styles.examplesNavListRightItemFirst} onClick={onExampleClicked} />
                </div>
                <div className={styles.separatorHorizontal}/>
                <div className={styles.examplesNavListRightWrapper}
                     ref={examplesNavListRight2Ref}
                     style={{ height:`calc(${calculatedHeight3}px)` }}
                >
                <Example text={user?.client?.questions[2]?.text || ''}  className={styles.examplesNavListRightItemSecond} value={user?.client?.questions[2]?.text} onClick={onExampleClicked} />
                </div>
            </div>
        </div>
    );
};
