import ErrorIcon from "../../assets/error-state.svg?react";

import styles from "./Answer.module.css";

interface Props {
    error: string;
    onRetry: () => void;
}

export const AnswerError = ({ error, onRetry }: Props) => {
    return (
        <div className={styles.answerContainer + ' ' + styles.errorAnswerContainer}>
            <ErrorIcon className={styles.answerErrorImage} />
            <div>
                <p className={styles.answerText}>{error}</p>
            </div>
            <button type="button" onClick={onRetry} className={styles.retryButton}>
                Retry
            </button>
        </div>
    );
};
