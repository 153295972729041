type TProps = {
    color?: string;
}
const LogOutComponent = (props: TProps) => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99383 6.85103C6.90946 6.93735 6.8273 7.02584 6.74744 7.11641C6.60417 7.27892 6.46831 7.44814 6.34041 7.62352C5.49743 8.77947 5 10.2035 5 11.7437C5 15.6097 8.13401 18.7437 12 18.7437C15.866 18.7437 19 15.6097 19 11.7437C19 9.77289 18.1855 7.99229 16.8748 6.72016M12 5V9" stroke={!!props.color ? props.color : 'white'} stroke-width="1.25" stroke-linecap="round"/>
        </svg>

    )
}

export default LogOutComponent