import React, { useState } from "react";
import SendBtnIcon from "../../assets/sendBtn.svg?react"

import styles from "./QuestionInput.module.css";
import "./QuestionInput.module.css";
import {Input, Space } from "antd";
import TextArea from "antd/es/input/TextArea";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    inside?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, inside }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }
        onSend(question);
        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = ( newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <div className={styles.questionInputContainer}>
            <Input
                className={`${styles.questionInputTextArea} ${inside ? styles.inside : ''} ${styles.fieldGroup}`}
                placeholder={placeholder}
                style={{width:'100%', maxWidth:"696px"}}
                value={question}
                onChange={e => onQuestionChange(e.target.value)}
                onKeyDown={onEnterPress}
                suffix={<div
                    className={`${styles.questionInputButtonsContainer} ${sendQuestionDisabled ? styles.suffixOverride : styles.suffixOverrideActive}`}>
                    <SendBtnIcon onClick={sendQuestion}/>
                </div>}
            />

            {/*<Input*/}
            {/*    className={styles.questionInputTextArea}*/}
            {/*    placeholder={placeholder}*/}
            {/*    value={question}*/}
            {/*    onChange={onQuestionChange}*/}
            {/*    onKeyDown={onEnterPress}*/}
            {/*    inputClassName={styles.questionInputTextArea}*/}
            {/*    suffix={sendQuestionDisabled ? styles.suffixOverride : styles.suffixOverrideActive}*/}
            {/*    styles={*/}
            {/*        {*/}
            {/*            fieldGroup: [styles.fieldGroup, inside && styles.inside],*/}
            {/*        }*/}
            {/*    }*/}
            {/*    // onRenderSuffix={() => <div className={styles.questionInputButtonsContainer}>*/}
            {/*    //     <SendBtnIcon  onClick={sendQuestion} />*/}
            {/*    // </div>}*/}
            {/*/>*/}
        </div>
    );
};
