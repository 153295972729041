import styles from "./Chat.module.css";
import {ExampleList} from "../../components/Example";
import {TPreviewProps} from "./types";
import {QuestionInput} from "../../components/QuestionInput";

const Preview = ({onExampleClicked, isLoading, makeApiRequest}: TPreviewProps) => {

    return (
        <div className={styles.chatEmptyStateContainer}>
        <div className={styles.chatEmptyState}>
            <div className={styles.chatEmptyStateTitle}>Chat with your data</div>
            <div className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</div>
            <div className={styles.chatInputPreview}>
                <QuestionInput
                    clearOnSend
                    placeholder="Type a new question here..."
                    disabled={isLoading}
                    onSend={question => makeApiRequest(question)}
                />
            </div>
             <ExampleList onExampleClicked={onExampleClicked}/>

        </div>
        </div>
    )
}

export default Preview